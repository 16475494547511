<template>
  <v-toolbar
    color="primary"
    dark
    flat
    dense
    src="/static/img/sso-1.png"
  >
    <template #img="{ props }">
      <v-img
        v-bind="props"
        position="left top"
        height="100%"
        gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
      />
    </template>
    <v-toolbar-title class="white--text text-left">
      <div class="text-left text-h4">
        {{ title }}
      </div>
    </v-toolbar-title>
  </v-toolbar>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Accedi a EpSSO'
    }
  },
  data () {
    return {
      // imgProps: { height: string, src: string | srcObject }
    }
  }
}
</script>
